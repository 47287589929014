import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import AdminHeader from "./AdminHeader";
import { FaEdit, FaPlus } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import { HiOutlineUsers } from "react-icons/hi";
import axios from "axios";
import { apiUrl } from "../../config";
import { CDBCard, CDBCardBody, CDBContainer, CDBDataTable } from "cdbreact";
import { Badge, Button, Form, FormControl, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaBan, FaCheck } from "react-icons/fa6";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [show, setShow] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseUpdateModal = () => setShowUpdateModal(false);

  const [selectedUser, setSelectedUser] = useState({
    id: null,
    updatedName: "",
    updatedEmail: "",
    updatedPassword: "",
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.post(`${apiUrl}users/getAllUsers`);
      setUsers(response.data.data); // Set users state with data from the API response
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleEnableDisableFeature = async (id, status) => {
    try {
      let response;
      if (status === 1) {
        // If feature is currently enabled, call disable API
        response = await axios.post(`${apiUrl}users/unBlockUser`, {
          userId: id,
        });
      } else {
        // If feature is currently disabled, call enable API
        response = await axios.post(`${apiUrl}users/blockUser`, {
          userId: id,
        });
      }

      if (response.data.status === true) {
        toast.success("Status updated successfully", {
          autoClose: 2000,
        });
        fetchUsers(); // Refresh the table data
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  const data = () => {
    const filteredUsers = users.filter(
      (user) =>
        user.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
        user.email.toLowerCase().includes(globalFilter.toLowerCase())
    );

    return {
      columns: [
        {
          label: "S.No",
          field: "sno",
          width: 50,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "S.No",
          },
        },
        {
          label: "Name",
          field: "name",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "User Type",
          field: "usertype",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          width: 200,
        },
        {
          label: "Plan Name",
          field: "planname",
          sort: "asc",
          width: 100,
        },
        {
          label: "Valid From",
          field: "validfrom",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Valid To",
          field: "validto",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          width: 200,
        },
        {
          label: "Actions",
          field: "actions",
          width: 150,
          sort: "disabled",
        },
      ],

      rows: filteredUsers.map((user, index) => ({
        sno: index + 1,
        name: user.name,
        usertype: user.userType,
        email: user.email,
        planname: user.planName,
        validfrom: formatDate(user.validFrom),
        validto: formatDate(user.validTo),
        status: (
          <span
            style={{
              backgroundColor: user.status === 1 ? "#dc3545" : "#28a745",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            {user.status === 1 ? "Blocked" : "Active"}
          </span>
        ),
        actions: (
          <>
            <Button
              variant={user.status === 1 ? "success" : "danger"}
              onClick={() => handleEnableDisableFeature(user.id, user.status)}
            >
              {user.status === 1 ? <FaCheck /> : <FaBan />}
            </Button>
            <Button variant="info" onClick={() => handleShowUpdateModal(user)}>
              <FaEdit />
            </Button>
          </>
        ),
      })),
    };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // You can adjust the format as per your requirement
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const userData = {
      name: formData.get("name"),
      email: formData.get("email"),
      password: formData.get("password"),
    };

    try {
      const response = await axios.post(`${apiUrl}users/addUser`, userData);
      if (response.data.status === true) {
        toast.success("User added successfully", {
          autoClose: 2000,
        });
        handleClose();
        fetchUsers();
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  const handleShowUpdateModal = (user) => {
    setSelectedUser({
      id: user.id,
      updatedName: user.name,
      updatedEmail: user.email,
      updatedPassword: user.password,
    });
    setShowUpdateModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    let userData; // Declare userData outside the if statement

    if (
      formData.get("editedpassword") == null ||
      formData.get("editedpassword") == ""
    ) {
      userData = {
        id: formData.get("id"),
        name: formData.get("editedname"),
        email: formData.get("editedemail"),
        password: null,
      };
    } else {
      userData = {
        id: formData.get("id"),
        name: formData.get("editedname"),
        email: formData.get("editedemail"),
        password: formData.get("editedpassword"),
      };
    }

    try {
      const response = await axios.post(`${apiUrl}users/updateUser`, userData);
      if (response.data.status === true) {
        toast.success("User updated successfully", {
          autoClose: 1000,
        });
        handleCloseUpdateModal();
        fetchUsers();
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <AdminHeader />
        <ToastContainer />
        <div style={{ display: "flex", flex: 1 }}>
          <Sidebar />
          <div
            className="dashmain"
            style={{ flex: 1, backgroundColor: "#F0F0F0", padding: "20px" }}
          >
            <h3>
              <HiOutlineUsers /> Users
            </h3>

            <div className="row">
              <div className="upload-but d-flex justify-content-end">
                <Button
                  className="but btn-web d-flex align-items-center"
                  variant="primary"
                  onClick={handleShow}
                >
                  <FaPlus /> Add User
                </Button>
              </div>
            </div>

            <div className="row mt-5">
              <Form className="w-25 mb-2 filter">
                <FormControl
                  type="text"
                  placeholder="Search by name"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </Form>
              <CDBContainer>
                <CDBCard>
                  <CDBCardBody>
                    <CDBDataTable
                      striped
                      bordered
                      hover
                      entriesOptions={[5, 10, 20]}
                      entries={5}
                      pagesAmount={4}
                      data={data()}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </CDBCardBody>
                </CDBCard>
              </CDBContainer>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control type="text" name="name" id="name" required />
              <Form.Label>Email:</Form.Label>
              <Form.Control type="text" name="email" id="email" required />
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                name="password"
                id="password"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Feature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdate}>
            <Form.Group>
              {/* <Form.Label>ID:</Form.Label> */}
              <Form.Control
                type="text"
                name="id"
                value={selectedUser.id}
                style={{ display: "none" }} // Hide the ID field
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                name="editedname"
                id="editedname"
                value={selectedUser.updatedName}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    updatedName: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="text"
                name="editedemail"
                id="editedemail"
                value={selectedUser.updatedEmail}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    updatedEmail: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                name="editedpassword"
                id="editedpassword"
                value={selectedUser.updatedPassword}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    updatedPassword: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default AdminUsers;
