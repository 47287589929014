import React from 'react'
import instantrht from "../../assets/images/instant_rht.png";

const HomeInstantlyCrack = () => {
  return (
    <div className='hme_ins_crck pt-5'>
        <div className="container">
            <div className="row">

                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='ins_crck_lft pt-0 pt-sm-5'>
                        <h2 style={{ fontSize:"38px",fontWeight:'700', color:'#fff', }}>Instantly craft an <span style={{color:'#005073',fontWeight:'700',fontSize:"38px"}}> AI chatbot </span> with just a few clicks </h2>
                        
                        <div className="pt-2 pt-sm-5">
                            <a href="register" className="btn btn-web fw-600">
                                Free Trial
                            </a>
                        </div>
                        
                    </div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='ins_crck_rht'>
                        <img className="img-fluid" src={instantrht} style={{ }} alt='instantrht'/>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default HomeInstantlyCrack;
