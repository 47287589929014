import React from "react";
import emd1 from "../../assets/images/emd1.png";
import emd2 from "../../assets/images/emd2.png";
import emd3 from "../../assets/images/emd3.png";

export const HomeEmbedding = () =>{
    return (
        <div className="hme_embd pt-0 pt-sm-4 pb-4 pb-sm-5 ">
            <div className="container">
                <div className="row text-center">
                    <h1><span>Embedding AI chatbots</span>into <span>websites</span> transforms them into dynamic tools, effortlessly guiding users through your content with precision and ease</h1>
                </div>

                <div className="row pt-4 pt-sm-5">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="fea-box text-center px-4" >
                            <img className="img-fluid w-25" src={emd1} style={{ }} alt='Feature1'/>
                            <h5 className="pt-4">AI Chatbot Enabled</h5>
                            <p className="pt-2" style={{ fontSize:"18px", textAlign:"justify" }}>Software program designed to engage in conversation with users, typically in natural language, providing information, assistance, or performing tasks</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="fea-box text-center px-4">
                            <img className="img-fluid w-25" src={emd2} style={{ }} alt='Feature1'/>
                            <h5 className="pt-4">AI Chatbot Enabled</h5>
                            <p className="pt-2" style={{ fontSize:"18px", textAlign:"justify" }}>Software program designed to engage in conversation with users, typically in natural language, providing information, assistance, or performing tasks</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="fea-box text-center px-4">
                            <img className="img-fluid w-25" src={emd3} style={{ }} alt='Feature1'/>
                            <h5 className="pt-4">AI Chatbot Enabled</h5>
                            <p className="pt-2" style={{ fontSize:"18px", textAlign:"justify" }}>Software program designed to engage in conversation with users, typically in natural language, providing information, assistance, or performing tasks</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};