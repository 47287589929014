// Import necessary modules
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { apiUrl } from "../../config";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS

// Validation schema
const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

// RegisterForm component
const RegisterFrom = () => {
  const navigate = useNavigate();

  const [initialFormValues] = useState({
    username: "",
    email: "",
    password: "",
  });

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      try {
        const requestedData = {
          name: values.username,
          email: values.email,
          password: values.password,
        };
        const response = await axios.post(
          `${apiUrl}auth/userRegister`,
          requestedData
        );
        //alert(response.data.status)
        if (response.data.status === true) {
          // Set a state indicating successful registration
          localStorage.setItem("registrationSuccess", "true");
          toast.success("Registration successful", { autoClose: 1000 });
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        } else {
          toast.error(response.data.message || "An error occurred");
        }
      } catch (error) {
        //console.error("Error:", error.message);
        toast.error("An error occurred");
      }
    },
  });

  return (
    <div className="">
      <ToastContainer /> {/* Render ToastContainer */}
      <form onSubmit={formik.handleSubmit}>
        <div className="form-floating mb-4">
          <input
            id="username"
            type="text"
            className="form-control"
            placeholder="Username"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <label htmlFor="username">Username</label>
          {formik.touched.username && formik.errors.username ? (
            <div className="text-danger">{formik.errors.username}</div>
          ) : null}
        </div>

        <div className="form-floating mb-4">
          <input
            id="email"
            type="email"
            className="form-control"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <label htmlFor="email">Email</label>
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>

        <div className="form-floating mb-4">
          <input
            id="password"
            type="password"
            className="form-control"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <label htmlFor="password">Password</label>
          {formik.touched.password && formik.errors.password ? (
            <div className="text-danger">{formik.errors.password}</div>
          ) : null}
        </div>

        <div className="mt-2">
          <button
            type="submit"
            className="btn btn-web display-flex align-items-center"
            style={{ fontSize: "14px" }}
          >
            Sign up
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterFrom;
