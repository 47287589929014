import React from "react";
import { Link } from "react-router-dom";

export const Navbar = () => {
  return (
    <ul className="navbar-nav ">
      <li class="nav-item">
        <a class="nav-link active" aria-current="page" href="#">
          Products
        </a>
      </li>

      <li class="nav-item">
      <a className="nav-link" href="#features">
          Features
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="#pricing">
          Pricing
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="#about">
          About us
        </a>
      </li>
    </ul>
  );
};
