import React from "react";
import logo from "../../assets/images/logo.png";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaUserLarge } from "react-icons/fa6";
import { IoMdLogOut } from "react-icons/io";
import { useNavigate } from "react-router";
import { Navbar } from "../../components/Header/Navbar";



export const UserDashHead = () => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        localStorage.removeItem('role');
        localStorage.removeItem('planId');
        // Redirect the user to the login page or any other appropriate page
        navigate('/'); // Assuming '/' is the login page URL
    };

    return (
        <div className="dashheader navbar_main py-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="usr_nav d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                        <div className="usr-nav-lft">
                            <a href="" className="">
                                <img className="img-fluid" src={logo} style={{ width: "200px" }} alt={logo} />
                            </a>
                        </div>
                       
                        <div className="usr-nav-rht">
                            <div className="">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <FaUserLarge />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="edit_profile">Edit Profile</Dropdown.Item>
                                        <Dropdown.Item onClick={logout}><IoMdLogOut />Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDashHead;
