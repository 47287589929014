import React from "react";
import { FaHome } from "react-icons/fa";
import LoginFrom from "../../features/Login/LoginFrom";



export const Login =() => {

    const frmLogin = {
        background: '#fff',
        boxShadow: '#4ec6fc6b 4px 4px 16px',
        padding: '40px',
        width: '36%',
        border: '1px solid #4ec6fc91',
        borderRadius: '10px',
    };

    return (

        <div className="login_bg  d-flex justify-content-center align-items-center" style={{height:'100vh',background:'#e5f7ff'}}>
            
            <div className="container">
                <div className="row">
                    <div className="login_form d-flex justify-content-center align-items-center flex-column ">
                        <div className="mb-5">
                            <a href="/" className="btn btn-web display-flex align-items-center" style={{fontSize:'14px'}}> <FaHome /> &nbsp; Back To Home Page</a>
                        </div>
                        <div className="frmLogin" style={frmLogin}>

                            <h4 className="pb-3">Sign in</h4>
                            <LoginFrom/>

                            <div className="mt-4">
                                <a href="/register" style={{fontSize:'14px'}}>Don't Have An Account ?</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}