import React from "react";
import { FaHome } from "react-icons/fa";
import RegisterFrom from "../../features/Register/RegisterFrom";


export const Register = () => {

    const frmLogin = {
        background: '#fff',
        boxShadow: '#4ec6fc6b 4px 4px 16px',
        padding: '40px',
        width: '36%',
        border: '1px solid #4ec6fc91',
        borderRadius: '10px',
    };

    return (
        <div className="register_bg d-flex justify-content-center align-items-center" style={{height:'100vh',background:'#e5f7ff'}}>
            <div className="container">
                <div className="row">

                <div className="register_form d-flex justify-content-center align-items-center flex-column ">
                        <div className="mb-5">
                            <a href="/" className="btn btn-web display-flex align-items-center" style={{fontSize:'14px'}}> <FaHome /> &nbsp; Back To Home Page</a>
                        </div>
                        <div className="frmLogin" style={frmLogin}>

                            <h4 className="pb-3">Sign up</h4>

                            <RegisterFrom/>

                            <div className="mt-4">
                                <a href="/login" style={{fontSize:'14px'}}>Already Have An Account ?</a>
                            </div>

                        </div>
                    </div>

                </div>   
            </div>
        </div>
    );
}


export default Register;