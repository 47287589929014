import "./App.css";
import "../src/assets/css/theme.css";
import "../src/assets/css/custom.css";

import 'bootstrap/dist/css/bootstrap.min.css'
import { MainRoutes } from "./Routes/MainRoutes";
function App() {
  return (
    <>
      <MainRoutes />
    </>
  );
}

export default App;
