import React from "react";
import logo from "../../assets/images/logo.png";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaPinterestSquare } from "react-icons/fa";

export const Footer = () => {
  const currentPath = window.location.pathname;

  console.log("current", currentPath);
  return (
    <div
      className={` ${currentPath != "/Contact" ? "footer-main" : "pt-5"}  `}
      style={{ background: "#E5F7FF" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="foot-logo">
              <a href="" className="navbar-brand">
                <img
                  className="img-fluid logo-size"
                  src={logo}
                  style={{ width: "200px" }}
                  alt={logo}
                />
              </a>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-3 foot_link pt-4 pt-sm-0">
            <h2
              className="pb-0 pb-sm-3"
              style={{ color: "#005073", fontSize: "18px", fontWeight: "600" }}
            >
              Company
            </h2>
            <p>
              <a href="#">About</a>
            </p>
            <p>
              <a href="#">Blog</a>
            </p>
            <p>
              <a href="#">Workflow</a>
            </p>
            <p>
              <a href="#">Testimonials</a>
            </p>
            <p>
              <a href="#">Privacy Policy</a>
            </p>
            <p>
              <a href="#">Terms of Service</a>
            </p>
            <p>
              <a href="#">Security & Certifications</a>
            </p>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-3 foot_link">
            <h2
              className="pb-0 pb-sm-3 pt-3 pt-sm-0 "
              style={{ color: "#005073", fontSize: "18px", fontWeight: "600" }}
            >
              Product
            </h2>
            <p>
              <a href="#">Features</a>
            </p>
            <p>
              <a href="#">Features List</a>
            </p>
            <p>
              <a href="#">Pricing</a>
            </p>
            <p>
              <a href="#">Solutions</a>
            </p>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-3 foot_link">
            <h2
              className="pb-0 pb-sm-3 pt-3 pt-sm-0"
              style={{ color: "#005073", fontSize: "18px", fontWeight: "600" }}
            >
              Support
            </h2>
            <p>
              <a href="#">Contact</a>
            </p>
            <p>
              <a href="#">Documentation</a>
            </p>
            <p>
              <a href="#">Support Center</a>
            </p>
            <p>
              <a href="#">Give Feedback</a>
            </p>
            <p>
              <a href="#">Partners </a>
            </p>
            <p>
              <a href="#">FAQ</a>
            </p>
            <p>
              <a href="#">Cookie Preferences </a>
            </p>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-3 foot_link">
            <h2
              className="pb-0 pb-sm-3 pt-3 pt-sm-0"
              style={{ color: "#005073", fontSize: "18px", fontWeight: "600" }}
            >
              Get in touch
            </h2>

            <p>
              <a href="#">
                <FaLinkedinIn />
                &nbsp; Linkedin
              </a>
            </p>
            <p>
              <a href="#">
                <FaTwitter />
                &nbsp; Twitter
              </a>
            </p>
            <p>
              <a href="#">
                <FaFacebookSquare />
                &nbsp; Facebook
              </a>
            </p>
            <p>
              <a href="#">
                <FaInstagram />
                &nbsp; Instagram
              </a>
            </p>
            <p>
              <a href="#">
                <FaYoutube />
                &nbsp; YouTube
              </a>
            </p>
            <p>
              <a href="#">
                <FaPinterestSquare />
                &nbsp; Pinterest
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
