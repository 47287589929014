import React from "react";
import Lottie from "lottie-react";
import data from "../../assets/videos/home_video.json";

export const HomeChatBot = () => {
  return (
    <div className="chat_bot py-3 py-sm-4" id="about">
      <div className="container">
        <div className="row">
          <h1 className="text-center">
            “<span> ThetaBot </span> is a distinctive product that interfaces
            and responds to customer inquiries with precision and efficiency”
          </h1>
        </div>

        <div className="row pt-4">
          {/* <ReactPlayer
            width="100%"
            height="100%"
            url={chatbox}
            loop
            playing
            muted
            controls={false}
            style={{ objectFit: "cover" }}
            className="homeVideo position-relative"
          /> */}
          <Lottie animationData={data} loop={true} />
          {/* <video
            src="https://webnox.blr1.digitaloceanspaces.com/hme_thetabox.b7351bc0a1aa3ed7fd83.mp4"
            loop
            autoPlay
            muted
            playsInline
            webkit-playsinline="true"
            controls={false}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            className="homeVideo position-relative"
          /> */}
        </div>
      </div> 
    </div>
  );
};
