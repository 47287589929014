import React from "react";
import fea1 from "../../assets/images/fea1.png";
import fea2 from "../../assets/images/fea2.png";
import fea3 from "../../assets/images/fea3.png";


export const HomeFeatures = () =>{

    return(

        <div className="home_feat py-3 py-sm-5" id="features" >
            <div className="container">
                <div className="row text-center">
                    <h1><span>OUR FEATURES</span></h1>
                    <h3 className="pt-2">Harness the potential of Artificial Intelligence using ThetaBot to facilitate effortless communication</h3>
                </div>

                <div className="row pt-4 pt-sm-5">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="fea-box text-center px-4 py-2 py-sm-0" >
                            <img className="img-fluid w-50" src={fea1} style={{ }} alt='Feature1'/>
                            <h5 className="pt-4">AI Chatbot Enabled</h5>
                            <p className="pt-2" style={{ fontSize:"18px", textAlign:"justify" }}>Software program designed to engage in conversation with users, typically in natural language, providing information, assistance, or performing tasks</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="fea-box text-center px-4 py-2 py-sm-0">
                            <img className="img-fluid w-50" src={fea2} style={{ }} alt='Feature1'/>
                            <h5 className="pt-4">Multiple LLM Support</h5>
                            <p className="pt-2" style={{ fontSize:"18px", textAlign:"justify" }}>Connects to multiple LLM's based on use case, and get the right output</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="fea-box text-center px-4 py-2 py-sm-0">
                            <img className="img-fluid w-50" src={fea3} style={{ }} alt='Feature1'/>
                            <h5 className="pt-4">Persistent Conversation</h5>
                            <p className="pt-2" style={{ fontSize:"18px", textAlign:"justify" }}>Customer conversations are tracked within your network to maintain state</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};