import React from "react";
import logo from "../../assets/images/logo.png";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";
const Sidebar = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#333",
        color: "#fff",
      }}
    >
      <CDBSidebar>
        <CDBSidebarHeader className="bars_icon" prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none"  style={{ color: "inherit" }}>
            <img className="img-fluid logo-size" src={logo} style={{ width: "130px" }} alt={logo}/>
          </a>
        </CDBSidebarHeader>
        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu className="nav_main">
            <NavLink exact to="/admin_dashboard" activeClassName="activeClicked" className="nav_link">
              <CDBSidebarMenuItem icon="home"> Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/admin_users" activeClassName="activeClicked" className="nav_link">
              <CDBSidebarMenuItem icon="user">Users</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/admin_packages" activeClassName="activeClicked" className="nav_link">
              <CDBSidebarMenuItem icon="archive">Packages</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/packages_features" activeClassName="activeClicked" className="nav_link">
              <CDBSidebarMenuItem icon="archive">Package Features</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/admin_upload" activeClassName="activeClicked" className="nav_link">
              <CDBSidebarMenuItem icon="upload">User Uploaded Document</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div
            style={{
              padding: "20px 5px",
            }}
          >
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};
export default Sidebar;







