import React, { useState, useEffect } from "react";
import UserDashHead from "./UserDashHead";
import axios from "axios";
import { apiUrl } from "../../config";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const EditProfile = () => {
  const [userData, setUserData] = useState({ name: "", phone: "" });
  const encryptedUserId = localStorage.getItem("userId");
  const bytes = CryptoJS.AES.decrypt(encryptedUserId, "theta");
  const userId = bytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}users/getUserById`,
          { userId }
        );

        // Assuming response.data contains user data with name and phone
        const { name, phone } = response.data.data;
        setUserData({ name, phone });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handlenameChange = (event) => {
    setUserData({ ...userData, name: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setUserData({ ...userData, phone: event.target.value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}users/profileUpdate`, {
        id:userId,
        name:userData.name,
        phone:userData.phone,
      });
      if (response.data.status === true) {
        toast.success("Profile updated successfully", {
          autoClose: 2000,
        });
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  return (
    <div>
      <UserDashHead />
      <ToastContainer />
      <div className="edit_prf">
        <h3>Edit Profile</h3>

        <div className="row ">
          <div className="w-50 m-auto border rounded p-5">
            <div className="row border rounded p-4">
              <h4 className="mb-3">Basic Info Update</h4>

              <div className="col-lg-6 col-md-6">
                <div className="form-floating mb-4">
                  <input
                    id="name"
                    type="text"
                    className="form-control"
                    placeholder="name"
                    value={userData.name}
                    onChange={handlenameChange}
                  />
                  <label htmlFor="name">name</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-floating mb-4">
                  <input
                    id="phone"
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    value={userData.phone}
                    onChange={handlePhoneChange}
                  />
                  <label htmlFor="phone">Phone</label>
                </div>
              </div>

              <div className="row">
                <button type="submit" className="btn btn-web display-flex align-items-center" style={{ fontSize: "14px" }} onClick={handleSubmit}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
