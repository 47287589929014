import React from "react";


export const Error401 = () => {


    return(
        <>
            Unauthorized action
        </>
    );

}

export default Error401;