import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import React from "react";

const HomeFaq = () => {
  
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: "#F7F7F7",
    borderRadius: token.borderRadiusLG,
    border: "1px solid #B4B4B4",
    fontWeight: 700,
    
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: "What is AI Theta Bot ?",
      children: `OneSea uses eco-friendly materials and practices, such as
          sustainable farming and ethical manufacturing, to minimize
          environmental impact.`,
      style: panelStyle,
    },
    {
      key: "2",
      label: " How to Integrate Theta bot in my website ?",
      children: `Yes, our products are hypoallergenic, ideal for newborns'
          delicate skin.`,
      style: panelStyle,
    },
    {
      key: "3",
      label: " How to get started ?",
      children: `Yes, our garments are designed for easy care, including
          machine washing.`,
      style: panelStyle,
    },
  ];

  return (
    <>
      <div className="hme_faq py-4 py-sm-5">
        <div className="container">
          <div className="row text-center">
            <h1>
              <span>FAQs</span>
            </h1>
          </div>


          <div className="row pt-4">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                style={{
                  background: token.colorBgContainer,
                }}
                items={getItems(panelStyle)}
              />
            </div>
            <div className="col-lg-2"></div>
          </div>

        </div>

      </div>
      
    </>
  );
};

export default HomeFaq;
