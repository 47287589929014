import React from "react";
import ContactFrom from "../../features/Contact/ContactFrom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";

export const Contact = () =>{

    const frmLogin = {
        background: '#fff',
        boxShadow: '#4ec6fc6b 4px 4px 16px',
        padding: '40px',
        width: '36%',
        border: '1px solid #4ec6fc91',
        borderRadius: '10px',
    };

    return(
        <>  

            <Header />

            <div className="login_bg  d-flex justify-content-center align-items-center" style={{height:'100vh',background:'#e5f7ff',borderBottom:'1px solid #0000002e', }}>
            <div className="container">
                <div className="row">
                    <div className="login_form d-flex justify-content-center align-items-center flex-column ">
                        
                        <div className="frmLogin" style={frmLogin}>
                        <h4 className="pb-3">Send Message</h4>

                            <ContactFrom />
                        </div>  
                    </div>
                </div>           
            </div>  
            </div>  

            <Footer />
        </>
    );

}


export default Contact;