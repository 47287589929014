import React from 'react';
import makeyour from "../../assets/images/makeyourimg.png";
import tick from "../../assets/images/tick.png";

const HomeMakeYour = () => {
  return (
    <div className='hme_mke_yur pt-5'>
      <div className='container'>

        <div className="row text-center">
            <h1><span>Make it Yours</span></h1>
            <h3 className="pt-2">Customized Bot Train Bot with data Change color, theme Transparent Chat logs</h3>
        </div>

        <div className='row pt-4 pt-sm-5'>
            <div className='col-lg-4 col-md-4 col-sm-12'>
                <div className='mke_yur_lft'>
                <img className="img-fluid w-75 pb-4 pb-sm-0" src={makeyour} style={{ }} alt='makeyour'/>
                </div>
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12' style={{display:'flex', alignItems:'center',}}>
                <div className='mke_yur_rht' style={{display:'inline-grid', gap:'30px',}}>
                    <p style={{ fontSize:"18px",display:'inline-flex', }}><img className="img-fluid" src={tick} style={{width:"20px",height:"20px",margin: '4px 0px 0px',}} alt='makeyour'/>&nbsp;Personalize your AI chatbot to complement your website's design</p>
                    <p style={{ fontSize:"18px",display:'inline-flex', }}><img className="img-fluid" src={tick} style={{width:"20px",height:"20px",margin: '4px 0px 0px',}} alt='makeyour'/>&nbsp;Fine-tune your AI chatbot to adeptly meet customer needs, enhancing satisfaction through personalized and responsive interactions</p>
                    <p style={{ fontSize:"18px",display:'inline-flex', }}><img className="img-fluid" src={tick} style={{width:"20px",height:"20px",margin: '4px 0px 0px',}} alt='makeyour'/>&nbsp;Utilize chatbot APIs to revolutionize task automation, creating a fluid, interactive experience that boosts productivity and user engagement.</p>
                </div>
            </div>
        </div>

      </div>
    </div>
  )
}

export default HomeMakeYour;
