import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaUserLarge } from "react-icons/fa6";
import { IoMdLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const AdminHeader = () => {
  const navigate = useNavigate();
  const checkAuthAndPaidStatus = () => {
    const encryptedUserId = localStorage.getItem("userId");
    if (
      encryptedUserId != null &&
      encryptedUserId != undefined &&
      encryptedUserId != ""
    ) {
      const bytes = CryptoJS.AES.decrypt(encryptedUserId, "theta");
      const userId = bytes.toString(CryptoJS.enc.Utf8);
      const role = localStorage.getItem("role");
      const userType = localStorage.getItem("userType");
      if (role !== "admin") {
        navigate("/error401");
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkAuthAndPaidStatus();
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userType");
    localStorage.removeItem("role");
    // Redirect the user to the login page or any other appropriate page
    navigate("/"); // Assuming '/' is the login page URL
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        height: "60px",
        backgroundColor: "#192235",
        color: "#fff",
        padding: "0 20px",
      }}
    >
      <div></div>
      <div>
        <Dropdown className="admin_drp">
          <Dropdown.Toggle id="dropdown-basic">
            <FaUserLarge />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item href="">Edit Profile</Dropdown.Item> */}
            <Dropdown.Item onClick={logout}>
              <IoMdLogOut />
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default AdminHeader;
