import React from 'react'
import app1 from "../../assets/images/app1.png";
import app2 from "../../assets/images/app2.png";
import app3 from "../../assets/images/app3.png";
import app4 from "../../assets/images/app4.png";
import app5 from "../../assets/images/app5.png";
import app6 from "../../assets/images/app6.png";
import app7 from "../../assets/images/app7.png";
import app8 from "../../assets/images/app8.png";
import app9 from "../../assets/images/app9.png";
import app10 from "../../assets/images/app10.png";
import app11 from "../../assets/images/app11.png";
import app12 from "../../assets/images/app12.png";

const HomeAiApp = () => {
  return (
    <div className='hme_ai pt-3 pt-sm-5'>
        <div className='container'>
            <div className="row text-center">
                <h1><span>Upgrade your AI chatbot</span> with plug-and-play integrations, adding new features and streamlining workflows</h1>
            </div>

            <div className="row">
                <ul className='app_list'>
                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app1} style={{ }} alt='app1'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app2} style={{ }} alt='app2'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app3} style={{ }} alt='app3'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app4} style={{ }} alt='app4'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app5} style={{ }} alt='app5'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app6} style={{ }} alt='app6'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app7} style={{ }} alt='app7'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app8} style={{ }} alt='app8'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app9} style={{ }} alt='app9'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app10} style={{ }} alt='app10'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app11} style={{ }} alt='app11'/>
                        </div>
                    </li>

                    <li className='app_items'>
                        <div className='app'>
                            <img className="img-fluid" src={app12} style={{ }} alt='app12'/>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    </div>
  );

  

};

export default HomeAiApp
