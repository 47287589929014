import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { apiUrl } from "../../config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router";
import { jwtDecode } from "jwt-decode";
const _ = require("lodash");

const HomePackages = () => {
  const [userPlanId, setPlanId] = useState(null);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log("decodedToken", decodedToken);
        const sessionplanId = localStorage.getItem("planId");
        console.log('sessionplanId',sessionplanId)
        //alert(sessionplanId)
        setPlanId(sessionplanId);
        // alert(userPlanId)
        // Check if decodedToken contains required properties
        if (
          decodedToken.hasOwnProperty("id") &&
          decodedToken.hasOwnProperty("name")
        ) {
          const { id, name, planId } = decodedToken;
          // setPlanId(planId);
          setUserName(name);
        } else {
          console.error("Token is missing required properties:", decodedToken);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [plans, setPlans] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = (plan) => {
    setSelectedPackage(plan);
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  const handlePayment = async (planId) => {
    try {
      const encryptedUserId = localStorage.getItem("userId");
      const bytes = CryptoJS.AES.decrypt(encryptedUserId, "theta");
      const userId = bytes.toString(CryptoJS.enc.Utf8);
      const response = await axios.post(
        `${apiUrl}payments/createPaymentCheckoutSession`,
        {
          userId: userId,
          planId: planId,
        }
      );
      if (response.data.status === true) {
        const paymentIntentUrl = response.data.paymentIntent.url;
        window.location.href = paymentIntentUrl;
      } else {
        toast.error("Failed to create checkout session!");
      }
    } catch (error) {
      toast.error("Failed to create checkout session!");
    }
  };

  const handleFreePlan = async (planId) => {
    try {
      const encryptedUserId = localStorage.getItem("userId");
      const bytes = CryptoJS.AES.decrypt(encryptedUserId, "theta");
      const userId = bytes.toString(CryptoJS.enc.Utf8);
      const response = await axios.post(`${apiUrl}payments/captureFreePlan`, {
        userId: userId,
        planId: planId,
      });
      if (response.data.status === true) {
        localStorage.setItem("userType", "paidUser");
        localStorage.setItem("planId", planId);
        toast.success("Plan purchased successfully!");
        setTimeout(() => {
          navigate("/user_dashboard");
      }, 1000);
      } else {
        toast.error(response.data.message);
        handleClose(true);
      }
    } catch (error) {
      toast.error("Server Error!");
    }
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${apiUrl}plans/getActivePlans`);
        if (response.status) {
          setPlans(response.data.data);
        } else {
          console.error("Failed to fetch plans:", response.message);
        }
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    if (selectedPackage) {
      // Calculate total amount including tax
      const taxAmount = selectedPackage.price * (selectedPackage.tax / 100);
      const total = selectedPackage.price + taxAmount;
      setTotalAmount(total);
    }
  }, [selectedPackage]);

  return (
    <div className="hme_packages" id="pricing">
      <ToastContainer />
      <div className="container">
        <div className="row text-center">
          <h1 className="my-5">
            <span>Our Packages</span>
          </h1>
        </div>

        <div className="row">
          <ul className="" style={pack_ul}>
            {plans.map((plan) => (
              <li key={plan.id} style={pack_li}>
                <div
                  className="package_box p-4"
                  style={{
                    border: "1px solid var(--primary)",
                    borderRadius: "10px",
                    background: "#bae4f6",
                  }}
                >
                  <div className="pack_bx_top mb-4">
                    <h6 className="text-center fs-16">{plan.name}</h6>
                    <h5 className="text-center">
                      {plan.price} $<span></span>
                    </h5>
                  </div>

                  <div className="pack_bx_cen">
                    {plan.features.map((feature) => (
                      <p key={feature.featureId}>
                        <FaCheck /> {feature.featureName}
                      </p>
                    ))}
                  </div>

                  <div className="text-center">
                    {userPlanId == plan.id ? (
                      <button
                        variant="primary"
                        className="btn btn-web fw-600 mt-3"
                      >
                        Current Plan
                      </button>
                    ) : (
                      <button
                        variant="primary"
                        onClick={() => handleShow(plan)}
                        className="btn btn-web fw-600 mt-3"
                      >
                        Select Package
                      </button>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedPackage ? selectedPackage.name : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedPackage ? (
                <div>
                  <p>{selectedPackage.description}</p>
                  <p>Price: {selectedPackage.price} $</p>
                  <p>Tax: {selectedPackage.tax}%</p>
                  <p>Total Amount: {totalAmount.toFixed(2)} $</p>
                  {/* Add other package details here */}
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              {selectedPackage && selectedPackage.price === 0 ? (
                <Button
                  variant="primary"
                  onClick={() => handleFreePlan(selectedPackage.id)}
                >
                  Activevate
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => handlePayment(selectedPackage.id)}
                >
                  Start Payment
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const pack_ul = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  listStyle: "none",
};

const pack_li = {
  display: "flex",
  alignSelf: "stretch",
};

export default HomePackages;
