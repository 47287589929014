import React from 'react'

const HomeGetStart = () => {
  return (
    <div className='hme_get-strt'>
      <div className='container'>
        <div className="row text-center">
            <h1><span>Get Started with Thetawaves</span></h1>
            <div className="pt-4">
                <btn className="btn btn-web fw-600">Try it yourself</btn>
            </div>
        </div>

        
        <div className='sign_up_frm mt-5' style={{ }}>
            <div className='row'>

                <div className='col-lg-5 col-md-12 col-sm-12'>
                   <div className='sin_lft'>
                      <h2 className='fw-700'>Sign up for Free</h2>
                   </div>
                </div>
                <div className='col-lg-7 col-md-12 col-sm-12'>
                  <div className='sin_rht' style={{display:'flex', width:'75%', }}>
                    <input type="text" className="form-control" id="subject" placeholder="Company Name" name="subject" required />
                    <btn className='btn btn-web fw-600 hme_sign_but'>Submit</btn>
                  </div>
                </div>
                
            </div>
        </div>

      </div>
    </div>
  );
};

export default HomeGetStart;
