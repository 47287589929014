import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import Table from "react-bootstrap/Table";
import axios from "axios"; // import axios for making HTTP requests
import { apiUrl } from "../../config";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import { Form, FormControl } from "react-bootstrap";
import { CDBCard, CDBCardBody, CDBContainer, CDBDataTable } from "cdbreact";
const _ = require("lodash");

export const UserMainDash = () => {
  const [show, setShow] = useState(false);
  const [fileInputs, setFileInputs] = useState([{ fileType: "", file: null }]); // State to manage file inputs
  const [globalFilter, setGlobalFilter] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [documents, setDocuments] = useState([]); // State to store fetched documents

  useEffect(() => {
    // Function to fetch documents from API
    const fetchDocuments = async () => {
      try {
        const encryptedUserId = localStorage.getItem("userId");
        const bytes = CryptoJS.AES.decrypt(encryptedUserId, "theta");
        const userId = bytes.toString(CryptoJS.enc.Utf8);

        const response = await axios.post(
          `${apiUrl}users/getUploadedDocuments`,
          { userId }
        );
        setDocuments(
          response.data.data.map((document) => ({
            ...document,
            createdAt: new Date(document.createdAt).toLocaleString(),
          }))
        );
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, []);

  const handleAddMoreFile = () => {
    setFileInputs([...fileInputs, { fileType: "", file: null }]);
  };

  const handleFileInputChange = (index, event) => {
    const { name, value, files } = event.target;
    const updatedFileInputs = [...fileInputs];
    updatedFileInputs[index] = {
      ...updatedFileInputs[index],
      [name]: name === "file" ? files[0] : value,
    };
    setFileInputs(updatedFileInputs);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      const formData = new FormData(); // Create a new FormData object
      formData.append("description", event.target.description.value); // Append form data

      // Construct files array
      const filesArray = [];
      for (const fileInput of fileInputs) {
        //const fileType = fileInput.fileType;
        const file = fileInput.file;
        const uploadResponse = await uploadFile(file);
        //console.log("uploadResponse", uploadResponse)
        if (!_.isNull(uploadResponse)) {
          filesArray.push({ fileType:uploadResponse.fileType,fileSize:uploadResponse.fileSize, file: uploadResponse.url });
          console.log("uploadResponse", uploadResponse)
        } else {
          toast.error("Failed to upload");
          return; // Exit function early if file upload fails
        }
      }

      const encryptedUserId = localStorage.getItem("userId");
      const bytes = CryptoJS.AES.decrypt(encryptedUserId, "theta");
      const userId = bytes.toString(CryptoJS.enc.Utf8);

      const requestBody = {
        userId,
        description: event.target.description.value,
        files: filesArray,
      };

      const submit = await submitFrom(requestBody);
      
      if (!_.isNull(submit)) {
        toast.success(submit.data.message);
        handleClose();
        window.location.reload();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  async function uploadFile(file) {
    try {
      const formData = new FormData();
      formData.append("userUploadedFiles", file);
      const response = await axios.post(`${apiUrl}users/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status === true) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  }

  async function submitFrom(requestBody) {
    try {
      const response = await axios.post(
        `${apiUrl}users/uploadDocument`,
        requestBody
      );
      if (response.data.status === true) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Error submitFrom file:", error);
      return null;
    }
  }

  const handleDownload = (url) => {
    // Function to initiate download
    window.open(url, "_blank");
  };

  const data = () => {
    const filtereDocuments = documents.filter((documents) =>
      documents.fileName.toLowerCase().includes(globalFilter.toLowerCase())
    );

    return {
      columns: [
        {
          label: "S.No",
          field: "sno",
          width: 50,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "S.No",
          },
        },
        {
          label: "File Type",
          field: "fileType",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "File Name",
          field: "fileName",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "File Size",
          field: "fileSize",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Description",
          field: "description",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Uploded At",
          field: "createdAt",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Status",
          field: "status",
          width: 150,
        },
        {
          label: "Actions",
          field: "actions",
          width: 150,
          sort: "disabled",
        },
      ],

      rows: filtereDocuments.map((document, index) => ({
        sno: index + 1,
        fileType: document.fileType,
        fileName: document.fileName.split("/").pop(),
        fileSize: document.fileSize,
        description: document.description,
        createdAt: new Date(document.createdAt).toLocaleString("en-US", {timeZone: "America/New_York"}), // Adjust timezone as needed
        status: document.status,
        actions: (
          <>
            <button
              className="btn btn-primary"
              onClick={() => handleDownload(document.fileName)}
            >
              Download
            </button>
          </>
        ),
      })),
    };
  };

  return (
    <div className="py-5">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="upload-but d-flex justify-content-end">
            <button
              className="but btn-web d-flex align-items-center"
              variant="primary"
              onClick={handleShow}
            >
              <FaPlus />
              &nbsp; Upload File
            </button>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-4">
                <label htmlFor="description">Description:</label>
                <input type="text" className="form-control" id="description" />
              </div>
              {fileInputs.map((fileInput, index) => (
                <div key={index}>
                  
                  <div className="form-group mb-4">
                    <label htmlFor={`file${index}`}>File:</label><br/>
                    <input
                      type="file"
                      name="file"
                      className="form-control-file"
                      id={`file${index}`}
                      onChange={(e) => handleFileInputChange(index, e)}
                    />
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="but btn-web me-3"
                onClick={handleAddMoreFile}
              >
                Add More File
              </button>
              <button  type="submit" className="but btn-web ">
                Submit
              </button>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="row">
          <div className="table mt-5">
            <Form className="w-25 mb-2 filter">
              <FormControl
                type="text"
                placeholder="Search by name"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </Form>
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <div className="table-responsive">
                    <CDBDataTable
                      striped
                      bordered
                      hover
                      entriesOptions={[5, 10, 20]}
                      entries={5}
                      pagesAmount={4}
                      data={data()}
                      searching
                      searchLabel="Search by name or email"
                      infoLabel={["Showing", "to", "of", "entries"]}
                      paginationLabel={["Previous", "Next"]}
                    />
                  </div>
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMainDash;
