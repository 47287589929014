import React from "react";
import { Container } from "react-bootstrap";
import { Navbar } from "./Navbar";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router";

export const Header = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userType");
    localStorage.removeItem("role");
    
    navigate("/");
  };
  
  return (
    <div className="">
      <nav className="navbar navbar-expand-lg  navbar_main">
        <div className="container">
          <a href="/" className="navbar-brand">
            <img
              className="img-fluid logo-size"
              src={logo}
              style={{ width: "200px" }}
              alt={logo}
            />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse"
            id="navbarNavDropdown"
            style={{ justifyContent: "center" }}
          >
            <Navbar />
          </div>

          <div
            className="collapse navbar-collapse "
            id="navbarNavDropdown"
            style={{ justifyContent: "right" }}
          >
            <ul className="navbar_nav_but">
              <li className="nav-item nav-but">
                <a className="btn btn_cn fw-600" href="/Contact">
                  Contact us
                </a>
              </li>
              <li>
                {/* Conditional rendering based on the presence of token */}
                {token ? (
                  // If token is present, render logout button
                  // <button className="btn btn-web fw-600" onClick={logout}>
                  //   Logout
                  // </button>
                  <a className="btn btn-web fw-600" href="/user_dashboard">
                    Dashboard
                  </a>
                ) : (
                  // If token is not present, render login button
                  <a className="btn btn-web fw-600" href="/login">
                    Login
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
