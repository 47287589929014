import React, { useState, useEffect } from "react";
import { Form, FormControl } from "react-bootstrap";
import Sidebar from "./Sidebar";
import AdminHeader from "./AdminHeader";
import { MdOutlineDashboard } from "react-icons/md";
import { GiPayMoney } from "react-icons/gi";
import { MdMoneyOff } from "react-icons/md";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { CDBCard, CDBCardBody, CDBContainer, CDBDataTable } from "cdbreact";
import axios from "axios";
import { apiUrl } from "../../config";
const DashMain = () => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [insightsData, setInsightsData] = useState(null);
  const [latestTransactions, setLatestTransactions] = useState([]);
  useEffect(() => {
    fetchInsights();
    fetchLatestTransactions();
  }, []);
  const fetchInsights = async () => {
    try {
      const response = await axios.post(`${apiUrl}users/getInsights`);
      const data = response.data;
      if (data.status) {
        setInsightsData(data.data);
      } else {
        console.error("Failed to fetch insights:", data.message);
      }
    } catch (error) {
      console.error("Error fetching insights:", error);
    }
  };
  const fetchLatestTransactions = async () => {
    try {
      const response = await axios.post(`${apiUrl}payments/getLatestTransactions`);
      setLatestTransactions(response.data.data);
    } catch (error) {
      console.error("Error fetching latest transactions:", error);
    }
  };
  const renderTransactions = () => {
    const filteredTransactions = latestTransactions.filter(
      (transaction) =>
        transaction.userName.toLowerCase().includes(globalFilter.toLowerCase()) ||
        transaction.userEmail.toLowerCase().includes(globalFilter.toLowerCase())
    );
    return {
      columns: [
        { label: "S.No", field: "sno", width: 50 },
        { label: "User Name", field: "userName", width: 150 },
        { label: "Email", field: "userEmail", width: 150 },
        { label: "Payment Id", field: "paymentId", width: 200 },
        { label: "Session Id", field: "sessionId", sort: "asc", width: 100 },
        { label: "Status", field: "status", sort: "disabled", width: 150 },
        { label: "Amount", field: "amount", sort: "disabled", width: 150 },
        { label: "Plan Name", field: "planName", sort: "disabled", width: 150 },
        { label: "Payment Method", field: "paymentMethod", width: 200 },
        {
          label: "Transaction Date",
          field: "transactionDate",
          width: 150,
          sort: "disabled",
        },
      ],
      rows: filteredTransactions.map((transaction, index) => ({
        sno: index + 1,
        userName: transaction.userName,
        userEmail: transaction.userEmail,
        paymentId: transaction.paymentId,
        sessionId: transaction.sessionId,
        status: transaction.status,
        amount: transaction.amount,
        planName: transaction.planName,
        paymentMethod: transaction.paymentMethod,
        transactionDate: formatDate(transaction.transactionDate),
      })),
    };
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // You can adjust the format as per your requirement
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <AdminHeader />
      <div style={{ display: "flex", flex: 1 }}>
        <Sidebar />
        <div
          className="dashmain"
          style={{ flex: 1, backgroundColor: "#F0F0F0", padding: "20px" }}
        >
          <h3>
            <MdOutlineDashboard /> Dashboard
          </h3>
          <div className="row mt-5">
            <ul
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                listStyle: "none",
              }}
            >
              <li style={{ flex: "1" }}>
                <div
                  className="package_box p-3 text-center"
                  style={{
                    border: "1px solid var(--primary)",
                    borderRadius: "10px",
                    background: "#BAE4F6",
                    width: "100%",
                  }}
                >
                  <i>
                    <FaUsers style={{ fontSize: "40px" }} />
                  </i>
                  <h6 className="pt-4">Total Users</h6>
                  <h5>
                    {insightsData ? insightsData.totalUsers : "Loading..."}
                  </h5>
                </div>
              </li>
              <li style={{ flex: "1" }}>
                <div
                  className="package_box p-3 text-center"
                  style={{
                    border: "1px solid var(--primary)",
                    borderRadius: "10px",
                    background: "#BAE4F6",
                    width: "100%",
                  }}
                >
                  <i>
                    <GiPayMoney style={{ fontSize: "40px" }} />
                  </i>
                  <h6 className="pt-4">Paid Users</h6>
                  <h5>
                    {insightsData ? insightsData.paidUserCount : "Loading..."}
                  </h5>
                </div>
              </li>
              <li style={{ flex: "1" }}>
                <div
                  className="package_box p-3 text-center"
                  style={{
                    border: "1px solid var(--primary)",
                    borderRadius: "10px",
                    background: "#BAE4F6",
                    width: "100%",
                  }}
                >
                  <i>
                    <MdMoneyOff style={{ fontSize: "40px" }} />
                  </i>
                  <h6 className="pt-4">Free Users</h6>
                  <h5>
                    {insightsData ? insightsData.freeUserCount : "Loading..."}
                  </h5>
                </div>
              </li>
              <li style={{ flex: "1" }}>
                <div
                  className="package_box p-3 text-center"
                  style={{
                    border: "1px solid var(--primary)",
                    borderRadius: "10px",
                    background: "#BAE4F6",
                    width: "100%",
                  }}
                >
                  <i>
                    <GiTakeMyMoney style={{ fontSize: "40px" }} />
                  </i>
                  <h6 className="pt-4">Total Revenue</h6>
                  <h5>
                    {insightsData ? insightsData.totalRevenue : "Loading..."}
                  </h5>
                </div>
              </li>
            </ul>
          </div>
          <div className="mt-4">
            <h4 className="fw-600">Latest Transactions</h4>
            <Form className="w-25 mb-2 filter">
              <FormControl
                type="text"
                placeholder="Search by name"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </Form>
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <div className="table-responsive">
                    <CDBDataTable
                      striped
                      bordered
                      hover
                      entriesOptions={[5, 10, 20]}
                      entries={5}
                      pagesAmount={4}
                      data={renderTransactions()}
                      searching
                      searchLabel="Search by name or email"
                      infoLabel={["Showing", "to", "of", "entries"]}
                      paginationLabel={["Previous", "Next"]}
                    />
                  </div>
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashMain;







