import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import AdminHeader from "./AdminHeader";
import { FaPlus } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import { HiOutlineUsers } from "react-icons/hi";
import axios from "axios";
import { apiUrl } from "../../config";
import { CDBCard, CDBCardBody, CDBContainer, CDBDataTable } from "cdbreact";
import { Form, FormControl, Modal, Button } from "react-bootstrap";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminUpload = () => {
  const [documents, setDocuments] = useState([]); // State to store fetched documents
  const [globalFilter, setGlobalFilter] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [status, setStatus] = useState(""); // State to store status selected in the modal
  const [selectedDocument, setSelectedDocument] = useState(null); // State to store the selected document

  useEffect(() => {
    // Function to fetch documents from API
    const fetchDocuments = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}users/getUploadedDocumentsAdmin`
        );
        setDocuments(
          response.data.data.map((document) => ({
            ...document,
            createdAt: new Date(document.createdAt).toLocaleString(), // Convert createdAt time format
          }))
        ); // Set fetched documents to state
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments(); // Call fetchDocuments when component mounts
  }, []);

  const data = () => {
    const filteredDocuments = documents.filter(
      (document) =>
        document.userName.toLowerCase().includes(globalFilter.toLowerCase()) ||
        document.userEmail.toLowerCase().includes(globalFilter.toLowerCase())
    );

    // Function to extract filename from URL
    const extractFilename = (url) => {
      const parts = url.split("/");
      const filename = parts[parts.length - 1];
      return filename;
    };

    // Modify filename for each document
    const rowsWithModifiedFileName = filteredDocuments.map((document) => ({
      ...document,
      fileName: extractFilename(document.fileName), // Extract filename from URL
    }));

    // Define action buttons for each row
    const rowsWithActionButtons = rowsWithModifiedFileName.map(
      (document, index) => ({
        ...document,
        serialNumber: index + 1, // Increment index by 1 to start with 1-based numbering
        action: (
          <div>
            <button onClick={() => handleStatus(document)}>Status</button>
            <button onClick={() => handleDownload(document.fileName)}>
              Download
            </button>
          </div>
        ),
      })
    );

    return {
      columns: [
        {
          label: "S.NO",
          field: "serialNumber", // Use serialNumber field for S.NO column
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "S.NO",
          },
        },
        {
          label: "User Name",
          field: "userName",
          width: 270,
        },
        {
          label: "Email",
          field: "userEmail",
          width: 200,
        },
        {
          label: "File Type",
          field: "fileType",
          sort: "asc",
          width: 100,
        },
        {
          label: "File Name",
          field: "fileName",
          sort: "disabled",
          width: 150,
        },
        {
          label: "File Size",
          field: "fileSize",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Description",
          field: "description",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          width: 200,
        },
        {
          label: "Uploaded At",
          field: "createdAt",
          width: 200,
        },
        {
          label: "Action",
          field: "action",
          width: 200,
        },
      ],
      rows: rowsWithActionButtons,
    };
  };

  const handleStatus = (document) => {
    setSelectedDocument(document);
    // Set the initial status value based on the status of the selected document
    setStatus(document.status);
    setShowModal(true); // Show the modal when status button is clicked
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  // const handleDownload = (document) => {
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = document.fileName;
  //   downloadLink.download = extractFilename("https://phpstack-977481-4352100.cloudwaysapps.com/uploads/userUploadedFiles/image-1709698394907.image%20(9).png");
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  const extractFilename = (url) => {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return filename;
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleStatusSubmit = async (id, status) => {
    try {
      const response = await axios.post(`${apiUrl}users/updateFileStatus`, {
        id: id,
        status: status,
      });
      if (response.data.status === true) {
        toast.success("Status updated successfully", {
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setShowModal(false);
  };

  const handleDownload = (fileName) => {
    const baseUrl = "https://phpstack-977481-4352100.cloudwaysapps.com/";
    const url = baseUrl + "uploads/userUploadedFiles/" + fileName;
    window.open(url, "_blank");
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <AdminHeader />
        <ToastContainer />
        <div style={{ display: "flex", flex: 1 }}>
          <Sidebar />
          <div
            className="dashmain"
            style={{ flex: 1, backgroundColor: "#F0F0F0", padding: "20px" }}
          >
            <h3>
              <HiOutlineUsers /> User Uploaded Files
            </h3>
            <div className="row mt-5">
              <Form className="w-25 mb-2 filter">
                <FormControl
                  type="text"
                  placeholder="Search by name"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </Form>
              <CDBContainer>
                <CDBCard>
                  <CDBCardBody>
                    <CDBDataTable
                      striped
                      bordered
                      hover
                      entriesOptions={[5, 10, 20]}
                      entries={5}
                      pagesAmount={4}
                      data={data()}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </CDBCardBody>
                </CDBCard>
              </CDBContainer>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for displaying status */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="statusDropdown">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={status}
                onChange={handleStatusChange}
              >
                <option value="">Select status</option>
                <option value="inProgress">In Progress</option>
                <option value="completed">Completed</option>
                <option value="pending">Pending</option>
              </Form.Control>
            </Form.Group>
            {/* Hidden input to store document id */}
            <Form.Control
              type="hidden"
              name="documentId"
              value={selectedDocument ? selectedDocument.id : ""}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleStatusSubmit(selectedDocument.id, status)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminUpload;
