import React from "react";

export const ContactFrom = () => {

    

    return (
        <div className=''>

            <div class="form-floating mb-3">
                <input type="text" class="form-control" placeholder="Username"/>
                <label>Username</label>
            </div>

            <div class="form-floating mb-3">
                <input type="text" class="form-control" placeholder="Email"/>
                <label>Email</label>
            </div>

            <div class="form-floating mb-3">
                <input type="number" class="form-control" placeholder="Phone Number"/>
                <label>Phone Number</label>
            </div>

            <div class="form-floating mb-3">
                <textarea class="form-control from-control-text" placeholder="Leave a comment here" ></textarea>
                <label>Comments</label>
            </div>

            <div className="mt-2">
                <a href="/" className="btn btn-web display-flex align-items-center" style={{fontSize:'14px'}}> Send </a>
            </div>

        </div>
    );

}

export default ContactFrom;