import React from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { HomeBanner } from "./HomeBanner";
import { HomeChatBot } from "./HomeChatBot";
import { HomeFeatures } from "./HomeFeatures";
import { HomeEmbedding } from "./HomeEmbedding";
import HomeInstantlyCrack from "./HomeInstantlyCrack";
import HomeMakeYour from "./HomeMakeYour";
import HomeAiApp from "./HomeAiApp";
import HomeFaq from "./HomeFaq";
import HomeGetStart from "./HomeGetStart";
import HomePackages from "./HomePackages";

export const Home = () => {
    return(
        <div className="">
            
            <Header />
            <HomeBanner />
            <HomeChatBot />
            <HomePackages />
            <HomeFeatures />
            <HomeEmbedding />
            <HomeInstantlyCrack />
            <HomeMakeYour />
            <HomeAiApp />
            <HomeFaq />
            <HomeGetStart />
            <Footer />

        </div>
    );
};
