import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import AdminHeader from "./AdminHeader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { apiUrl } from "../../config";
import Table from "react-bootstrap/Table";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPlus, FaEdit, FaBan } from "react-icons/fa";
import { CDBCard, CDBCardBody, CDBContainer, CDBDataTable } from "cdbreact";
import { FormControl } from "react-bootstrap";
import { FaLock, FaUnlock } from "react-icons/fa6";

const PackagesFeature = () => {
  const [show, setShow] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [formData, setFormData] = useState({
    features: "",
  });
  const [errors, setErrors] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState({
    id: null,
    updatedfeature: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseUpdateModal = () => setShowUpdateModal(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(`${apiUrl}plans/getFeatures`);
      setTableData(response.data.features);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.features.trim() === "") {
      setErrors({
        features: "Features is required",
      });
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}plans/addFeature`, {
        feature: formData.features,
      });
      if (response.data.status === true) {
        toast.success("Feature added successfully", {
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleShowUpdateModal = (id, feature) => {
    setSelectedFeature({ id, updatedfeature: feature });
    setShowUpdateModal(true);
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setSelectedFeature({
      ...selectedFeature,
      [name]: value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}plans/updateFeature`, {
        id: selectedFeature.id,
        feature: selectedFeature.updatedfeature,
      });
      if (response.data.status === true) {
        toast.success("Feature updated successfully", {
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEnableDisableFeature = async (id, status) => {
    try {
      let response;
      if (status === 1) {
        // If feature is currently enabled, call disable API
        response = await axios.post(`${apiUrl}plans/enableFeature`, {
          featureId: id,
        });
      } else {
        // If feature is currently disabled, call enable API
        response = await axios.post(`${apiUrl}plans/disableFeature`, {
          featureId: id,
        });
      }

      if (response.data.status === true) {
        toast.success("Feature status updated successfully", {
          autoClose: 2000,
        });
        fetchData(); // Refresh the table data
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const data = () => {
    const filteredFeatures = tableData.filter((tableData) =>
      tableData.feature.toLowerCase().includes(globalFilter.toLowerCase())
    );

    return {
      columns: [
        {
          label: "S.No",
          field: "sno",
          width: 50,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "S.No",
          },
        },
        {
          label: "Feature",
          field: "feature",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Status",
          field: "status",
          width: 150,
        },
        {
          label: "Actions",
          field: "actions",
          width: 150,
          sort: "disabled",
        },
      ],

      rows: filteredFeatures.map((feature, index) => ({
        sno: index + 1,
        feature: feature.feature,
        status: (
          <span
            style={{
              backgroundColor: feature.status === 1 ? "#dc3545" : "#28a745",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            {feature.status === 0 ? "Active" : "Inactive"}
          </span>
        ),
        actions: (
          <>
            <Button
              variant="info"
              onClick={() => handleShowUpdateModal(feature.id, feature.feature)}
            >
              <FaEdit />
            </Button>
            <Button
              variant={feature.status === 1 ? "warning" : "success"}
              onClick={() =>
                handleEnableDisableFeature(feature.id, feature.status)
              }
            >
              {feature.status === 1 ? <FaUnlock /> : <FaLock />}
            </Button>
          </>
        ),
      })),
    };
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <AdminHeader />
        <ToastContainer />
        <div style={{ display: "flex", flex: 1 }}>
          <Sidebar />
          <div
            className="dashmain"
            style={{ flex: 1, backgroundColor: "#f0f0f0", padding: "20px" }}
          >
            <h3>
              <HiClipboardDocumentList /> Features
            </h3>
            <div className="row">
              <div className="upload-but d-flex justify-content-end">
                <Button
                  className="but btn-web d-flex align-items-center"
                  variant="primary"
                  onClick={handleShow}
                >
                  <FaPlus /> Add Feature
                </Button>
              </div>
            </div>
            <Form className="w-25 mb-2 filter">
              <FormControl
                type="text"
                placeholder="Search by name"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </Form>
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    entriesOptions={[5, 10, 20]}
                    entries={5}
                    pagesAmount={4}
                    data={data()}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Feature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Features:</Form.Label>
              <Form.Control
                type="text"
                name="features"
                value={formData.features}
                onChange={handleChange}
              />
              {errors.features && (
                <Form.Text className="text-danger">{errors.features}</Form.Text>
              )}
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Feature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdate}>
            <Form.Group>
              {/* <Form.Label>ID:</Form.Label> */}
              <Form.Control
                type="text"
                name="id"
                value={selectedFeature.id}
                style={{ display: "none" }} // Hide the ID field
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Feature:</Form.Label>
              <Form.Control
                type="text"
                name="updatedfeature"
                value={selectedFeature.updatedfeature}
                onChange={handleUpdateChange} // corrected here
              />
              {errors.features && (
                <Form.Text className="text-danger">{errors.features}</Form.Text>
              )}
            </Form.Group>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PackagesFeature;
