import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
// import { useLocation } from "react-router-dom";
// import { apiUrl } from "../../config";
// import axios from "axios";

export const PaymentFailed = () => {
    // const [paymentStatus, setPaymentStatus] = useState("pending");
    // const [paymentId, setPaymentId] = useState(null);
    // const location = useLocation();

    const Paybox ={
        background: '#fff',
        boxShadow: '#4ec6fc6b 4px 4px 16px',
        padding: '40px',
        width: '36%',
        border: '1px solid #4ec6fc91',
        borderRadius: '10px',
    };
    const FaCheckStyles = {
        fontSize: '20px',
        border: '3px solid #bb2124',
        width: '60px',
        height: '60px',
        padding: '10px',
        borderRadius: '30px',
        color: '#bb2124',
    }

    // useEffect(() => {
    //     const params = new URLSearchParams(location.search);
    //     const paymentIdParam = params.get("payment_intent");
    //     const userId = params.get("userId");
    //     const planId = params.get("planId");
    
    //     if (paymentIdParam) {
    //       updatePaymentStatus(paymentIdParam, userId, planId);
    //     }
    //   }, [location]);
    
    //   const updatePaymentStatus = async (paymentIdParam, userId, planId) => {
    //     try {
    //       const requestedData = {
    //         userId: userId,
    //         sessionId: paymentIdParam,
    //         planId: planId
    //       };
    //       const response = await axios.post(`${apiUrl}payments/capturePaymentResponseSuccess`,requestedData);
    //       alert(response.data.status)
    //       ///console.log("response", response)
    //     } catch (error) {
    //       console.error("Error updating payment status:", error);
    //     }
    //   };

    return(

        <div className="payment-success  d-flex justify-content-center align-items-center" style={{height:'100vh',background:'#e5f7ff'}}>
            <div className="container">
                <div className="row justify-content-center">

                    <div className="pay_box d-flex justify-content-center align-items-center flex-column text-center" style={Paybox}>
                        <i className="pay_icon mb-3"> <FaTimes style={FaCheckStyles} /> </i>
                        <h3 className="mb-2">Payment Failed</h3>
                        <p className="mb-0">Unfortunately payment was rejected</p>
                        <p>Go to the main page click below button.</p>

                        <div className="text-left text-sm-center pt-2 w-100">
                            <a className="btn btn-web fw-600 w-100" href="/">Home</a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
};

export default PaymentFailed;
