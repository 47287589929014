import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom"; // Import Redirect if you're using react-router-dom
import { UserDashHead } from "./UserDashHead";
import UserMainDash from "./UserMainDash";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom"; // Import useNavigate if you're using react-router-dom v6

const UserDashboard = () => {
    const navigate = useNavigate(); 
  const checkAuthAndPaidStatus = () => {
    const encryptedUserId = localStorage.getItem("userId");
    if (encryptedUserId != null && encryptedUserId != undefined && encryptedUserId != "") {
        const bytes = CryptoJS.AES.decrypt(encryptedUserId, "theta");
        const userId = bytes.toString(CryptoJS.enc.Utf8);
        const role = localStorage.getItem("role");
        const userType = localStorage.getItem("userType");
        if (userType === "freeUser") {
            navigate("/packages");
        }
    } else {
        navigate("/login");
    }
  };

  useEffect(() => {
    checkAuthAndPaidStatus();
  }, []);

  return (
    <div className="">
      <UserDashHead />
      <UserMainDash />
    </div>
  );
};

export default UserDashboard;
