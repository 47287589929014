import { useFormik } from "formik";
import * as Yup from "yup";
import { apiUrl } from "../../config";
import { useNavigate } from "react-router";
import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS
import CryptoJS from "crypto-js";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginFrom = () => {
  const navigate = useNavigate();

  const [initialFormValues] = useState({
    email: "",
    password: "",
  });

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      //console.log("  form data", values);

      try {
        const requestedData = {
          email: values.email,
          password: values.password,
        };

        //console.log("requestedData.........", requestedData);

        const response = await axios.post(`${apiUrl}auth/login`, requestedData);
        if (response.data.status === true) {
          const userId = response.data.user.id;
          const role = response.data.user.role;
          const userType = response.data.user.userType;
          const planId = response.data.user.planId;
         
          // Encrypting userId with an encryption key
          const encryptedUserId = CryptoJS.AES.encrypt(
            userId.toString(),
            "theta"
          ).toString();
          
          localStorage.setItem("userId", encryptedUserId);
          localStorage.setItem("role", role);
          localStorage.setItem("userType", userType);
          localStorage.setItem("token", response.data.accessToken);
          localStorage.setItem("planId", planId);
  
          if (response.data.user.role === "admin") {
            navigate("/admin_dashboard");
          } else {
            navigate("/user_dashboard");
          }
        } else {
          toast.error(response.data.message || "An error occurred");
        }
      } catch (error) {
        toast.error("Failed to login");
        //console.log("error", error)
      }
    },
  });

  return (
    <div className="">
      <ToastContainer /> {/* Render ToastContainer */}
      <form onSubmit={formik.handleSubmit}>
        <div className="form-floating mb-4">
          <input
            id="email"
            type="email"
            className="form-control"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <label htmlFor="email">Email address</label>

          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>

        <div className="form-floating mb-4">
          <input
            id="password"
            type="password"
            className="form-control"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <label htmlFor="password">Password</label>
          {formik.touched.password && formik.errors.password ? (
            <div className="text-danger">{formik.errors.password}</div>
          ) : null}
        </div>

        <div className="fp">
          <a href="forgot-pasword" style={{fontSize:'14px'}}>Forgot Password</a>
        </div>

        <div className="mt-2">
          <button
            type="submit"
            className="btn btn-web display-flex align-items-center"
            style={{ fontSize: "14px" }}
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginFrom;
