import React, { useEffect, useState } from "react";
import banrht from "../../assets/images/banner_rht.png";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import { apiUrl } from "../../config";
import axios from "axios";
import { useNavigate } from "react-router";

export const HomeBanner = () => {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${apiUrl}plans/getActiveFreePlan`);
        if (response.status) {
          console.log(response.data.data)
          setPlans(response.data.data);
        } else {
          console.error("Failed to fetch plans:", response.message);
        }
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    fetchPlans();
  }, []);
  const handleFreePlan = async (planId) => {
    //alert(planId)
    try {
      const encryptedUserId = localStorage.getItem("userId");
      if (encryptedUserId == null) {
        navigate("/login");
      } else {
        const bytes = CryptoJS.AES.decrypt(encryptedUserId, "theta");
        const userId = bytes.toString(CryptoJS.enc.Utf8);
        const response = await axios.post(`${apiUrl}payments/captureFreePlan`, {
          userId: userId,
          planId: planId,
        });
        if (response.data.status === true) {
          localStorage.setItem("userType", "paidUser");
          localStorage.setItem("planId", planId);
          toast.success("Plan purchased successfully!");
          setTimeout(() => {
            navigate("/user_dashboard");
          }, 1000);
        } else {
          toast.error(response.data.message);
          //handleClose(true);
        }
      }
    } catch (error) {
      console.log("error", error)
      toast.error("Server Error!");
    }
  };

  return (
    <div className="home_ban py-5">
      <div className="container">
        <ToastContainer />
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12 pb-4 pb-sm-0">
            <h1 className="">
              Transform interactions into customer engagements with{" "}
              <span> AI chatbot </span> technology
            </h1>
            <h4 className="pt-3">
              Personalize your chat interactions for web
              <br className="brh4" /> and mobile with ThetaWaves
            </h4>
            <div className="text-left text-sm-center pt-4 w-50">
              <Button
                className="btn btn-web fw-600"
                onClick={() => handleFreePlan(plans.id)}
              >
                Free Trial
              </Button>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12">
            <img
              className="img-fluid banner_right"
              src={banrht}
              style={{}}
              alt="banner_right"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
