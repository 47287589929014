import React from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import HomePackages from "../Home/HomePackages";

export const Package = () => {
  return (
    <div className="">
      <Header />
      <div className="mb-5">
        <h5 className="text-center mt-4">
          Please purchase a plan first if you want to use the services.
        </h5>
        <HomePackages />
      </div>
      <Footer />
    </div>
  );
};
