import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import Register from "../pages/Register";
import Contact from "../pages/ContactUs";
import UserDashboard from "../pages/UserDashboard";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentFailed from "../pages/PaymentFailed";
import { Package } from "../pages/Packages";
import DashMain from "../components/Adminsidebar/DashMain";
import AdminUsers from "../components/Adminsidebar/AdminUsers";
import AdminPackages from "../components/Adminsidebar/AdminPackages";
import PackagesFeature from "../components/Adminsidebar/PackagesFeature";
import AdminUpload from "../components/Adminsidebar/AdminUpload";
import Error401 from "../pages/Error401";
import EditProfile from "../pages/UserDashboard/EditProfile";
import ForgetPassword from "../pages/ForgetPassword";


export const MainRoutes = () => {
    
    return (
        <>
        <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="Contact" element={<Contact />}></Route>
          <Route path="/user_dashboard" element={<UserDashboard/>}></Route>
          <Route path="/payment_success" element={<PaymentSuccess />}></Route>
          <Route path="payment_failed" element={<PaymentFailed/>}></Route>
          <Route path="packages" element={<Package/> }></Route>
          <Route path="admin_dashboard" element={<DashMain/>}></Route>
          <Route path="admin_users" element={<AdminUsers/>}></Route>
          <Route path="admin_packages" element={<AdminPackages/>}></Route>
          <Route path="admin_upload" element={<AdminUpload/>}></Route>
          <Route path="packages_features" element={<PackagesFeature/>}></Route>
          <Route path="edit_profile" element={<EditProfile/>}></Route>
          <Route path="error401" element={<Error401/>}></Route>
          <Route path="forgot-pasword" element={<ForgetPassword/>}></Route>


        </Routes>
      </Router>
        </>
    )
}