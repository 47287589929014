import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import AdminHeader from "./AdminHeader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Multiselect from "multiselect-react-dropdown";
import { FaPlus, FaCheck } from "react-icons/fa";
import { TbPackages } from "react-icons/tb";
import { apiUrl } from "../../config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminPackages = () => {
  const [show, setShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [plans, setPlans] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [duration, setDuration] = useState("");
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [editingPlan, setEditingPlan] = useState(null); // Holds the plan data being edited
  const [selectedFeaturesForEdit, setSelectedFeaturesForEdit] = useState(null); // Holds the plan data being edited

  useEffect(() => {
    fetchPlans();
    fetchFeatures();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${apiUrl}plans/getPlans`);
      const data = response.data.data;
      setPlans(data);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const fetchFeatures = async () => {
    try {
      const response = await axios.post(`${apiUrl}plans/featuresForDropDown`);
      const data = response.data.features;

      // Map features to the format expected by Multiselect
      const formattedFeatures = data.map((feature) => ({
        key: feature.feature,
        value: feature.id,
      }));

      setFeatureOptions(formattedFeatures);
    } catch (error) {
      console.error("Error fetching features:", error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectedFeatures([]); // Reset selected features when closing the add modal
  };

  const handleShow = () => setShow(true);

  // const handleEditModalShow = (plan) => {
  //   setEditingPlan(plan);

  //   // Set selected features to match the features of the plan being edited
  //   setSelectedFeatures(plan.features.map(feature => ({ key: feature.featureName, value: feature.id })));

  //   setEditModalShow(true);
  // };

  const handleEditModalShow = async (plan) => {
    setEditingPlan(plan);

    try {
      const response = await axios.post(`${apiUrl}plans/getPlanById`, {
        planId: plan.id,
      });
      const planData = response.data.data[0];
      const features = planData.features;

      setSelectedFeaturesForEdit(
        features.map((feature) => ({
          key: feature.featureName,
          value: feature.featureId,
        }))
      );
      setEditModalShow(true);
    } catch (error) {
      console.error("Error fetching plan by ID:", error);
    }
  };

  const handleEditModalClose = () => setEditModalShow(false);

  const handleSubmit = async () => {
    // Input validation
    if (!name || !description || !price || !tax || !duration) {
      toast.error("Please fill in all fields");
      return;
    }

    if (
      isNaN(parseFloat(price)) ||
      isNaN(parseFloat(tax)) ||
      isNaN(parseInt(duration))
    ) {
      toast.error("Price, Tax, and Duration must be numbers");
      return;
    }

    if (selectedFeatures.length === 0) {
      toast.error("Please select at least one feature");
      return;
    }

    try {
      const selectedFeatureIds = selectedFeatures
        .map((feature) => feature.value)
        .join(",");

      const response = await axios.post(`${apiUrl}plans/addPlan`, {
        name,
        description,
        price: parseFloat(price),
        tax: parseFloat(tax),
        duration: parseInt(duration),
        features: selectedFeatureIds,
      });
      if (response.data.status === true) {
        toast.success("Package added successfully");
        handleClose();
        fetchPlans();
        fetchFeatures();
      }
    } catch (error) {
      console.error("Error adding package:", error);
      toast.error("Error adding package");
    }
  };

  const handleEditSubmit = async () => {
    // Input validation
    if (
      !editingPlan.name ||
      !editingPlan.description ||
      !editingPlan.price ||
      !editingPlan.tax ||
      !editingPlan.duration
    ) {
      toast.error("Please fill in all fields");
      return;
    }

    if (
      isNaN(parseFloat(editingPlan.price)) ||
      isNaN(parseFloat(editingPlan.tax)) ||
      isNaN(parseInt(editingPlan.duration))
    ) {
      toast.error("Price, Tax, and Duration must be numbers");
      return;
    }

    if (selectedFeaturesForEdit.length === 0) {
      toast.error("Please select at least one feature");
      return;
    }

    // Capture selected feature IDs here
    const selectedFeatureIds = selectedFeaturesForEdit
      .map((feature) => feature.value)
      .join(",");
    try {
      const response = await axios.post(`${apiUrl}plans/updatePlan`, {
        id: editingPlan.id,
        name: editingPlan.name,
        description: editingPlan.description,
        price: parseFloat(editingPlan.price),
        tax: parseFloat(editingPlan.tax),
        duration: parseInt(editingPlan.duration),
        features: selectedFeatureIds,
      });
      if (response.data.status === true) {
        toast.success("Package updated successfully");
        handleEditModalClose();
        fetchPlans();
        fetchFeatures();
      }
    } catch (error) {
      toast.error("Error updating package");
    }
  };

  const enablePlan = async (planId) => {
    try {
      const response = await axios.post(`${apiUrl}plans/enablePlan`, {
        planId: planId,
      });
      if (response.data.status === true) {
        toast.success("Status updated successfully", {
          autoClose: 2000,
        });
        fetchPlans();
        fetchFeatures();
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  const disablePlan = async (planId) => {
    try {
      const response = await axios.post(`${apiUrl}plans/disablePlan`, {
        planId: planId,
      });
      if (response.data.status === true) {
        toast.success("Status updated successfully", {
          autoClose: 2000,
        });
        fetchPlans();
        fetchFeatures();
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <AdminHeader />
        <ToastContainer />
        <div style={{ display: "flex", flex: 1 }}>
          <Sidebar />
          <div
            className="dashmain"
            style={{ flex: 1, backgroundColor: "#f0f0f0", padding: "20px" }}
          >
            <h3>
              <TbPackages /> Packages
            </h3>
            <div className="row">
              <div className="upload-but d-flex justify-content-end">
                <Button
                  className="but btn-web d-flex align-items-center"
                  variant="primary"
                  onClick={handleShow}
                >
                  <FaPlus /> Add Package
                </Button>
              </div>
            </div>
            <div className="row pt-5">
              <ul
                className=""
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 0fr)",
                  listStyle: "none",
                  justifyContent: "space-evenly",
                  gap: "40px",
                }}
              >
                {plans.map((plan, index) => (
                  <li key={index} style={{ display: "flex" }}>
                    <div
                      className="package_box p-4"
                      style={{
                        border: "1px solid var(--primary)",
                        borderRadius: "10px",
                        background: "#bae4f6",
                      }}
                    >
                      <div className="pack_bx_top mb-4">
                        <h6 className="text-center fs-16">{plan.name}</h6>
                        <h5 className="text-center">
                          {plan.price} $<span></span>
                        </h5>
                      </div>
                      <div className="pack_bx_cen">
                        {plan.features.map((feature, i) => (
                          <p key={i}>
                            <FaCheck /> {feature.featureName}
                          </p>
                        ))}
                      </div>
                      <div className="text-center d-flex align-items-center mt-4">
                        <Button
                          className="btn btn-web fw-600 me-4 w-70 pY-4 pX-5"
                          onClick={() => handleEditModalShow(plan)}
                        >
                          Edit
                        </Button>
                        {plan.status === 1 ? (
                          <Button
                            className="btn btn-web fw-600 me-4 w-70 pY-4 pX-5"
                            onClick={() => enablePlan(plan.id)}
                          >
                            Enable
                          </Button>
                        ) : (
                          <Button
                            className="btn btn-web fw-600 me-4 w-70 pY-4 pX-5"
                            onClick={() => disablePlan(plan.id)}
                          >
                            Disable
                          </Button>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPackageName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter package name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPackageDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter package description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPackagePrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter package price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPackagePrice">
              <Form.Label>Tax</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter package price"
                value={tax}
                onChange={(e) => setTax(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPackageDuration">
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter package duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPackageFeatures">
              <Form.Label>Features</Form.Label>
              <Multiselect
                options={featureOptions}
                displayValue="key"
                style={{
                  chips: { background: "red" },
                  searchBox: {
                    border: "none",
                    borderBottom: "1px solid blue",
                    borderRadius: "0px",
                  },
                }}
                onSelect={(selectedList) => setSelectedFeatures(selectedList)}
                onRemove={(selectedList) => setSelectedFeatures(selectedList)}
                selectedValues={selectedFeatures}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editModalShow} onHide={handleEditModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {editingPlan && (
              <>
                <Form.Group controlId="formPackageName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter package name"
                    value={editingPlan.name}
                    onChange={(e) =>
                      setEditingPlan({ ...editingPlan, name: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formPackageDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter package description"
                    value={editingPlan.description}
                    onChange={(e) =>
                      setEditingPlan({
                        ...editingPlan,
                        description: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formPackagePrice">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter package price"
                    value={editingPlan.price}
                    onChange={(e) =>
                      setEditingPlan({ ...editingPlan, price: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formPackageTax">
                  <Form.Label>Tax</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter package tax"
                    value={editingPlan.tax}
                    onChange={(e) =>
                      setEditingPlan({ ...editingPlan, tax: e.target.value })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formPackageDuration">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter package duration"
                    value={editingPlan.duration}
                    onChange={(e) =>
                      setEditingPlan({
                        ...editingPlan,
                        duration: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formPackageFeatures">
                  <Form.Label>Features</Form.Label>
                  <Multiselect
                    options={featureOptions}
                    displayValue="key"
                    style={{
                      chips: { background: "red" },
                      searchBox: {
                        border: "none",
                        borderBottom: "1px solid blue",
                        borderRadius: "0px",
                      },
                    }}
                    onSelect={(selectedList) =>
                      setSelectedFeaturesForEdit(selectedList)
                    }
                    onRemove={(selectedList) =>
                      setSelectedFeaturesForEdit(selectedList)
                    }
                    selectedValues={selectedFeaturesForEdit}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminPackages;
