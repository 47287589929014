import React, { useState, useEffect } from "react";
import { FaHome } from 'react-icons/fa';
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS
import { apiUrl } from "../../config";
import axios from "axios";
import { useNavigate } from "react-router";
import CryptoJS from "crypto-js";

export const ForgetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [showOtpPasswordForm, setShowOtpPasswordForm] = useState(false);

    const frmLogin = {
        background: '#fff',
        boxShadow: '#4ec6fc6b 4px 4px 16px',
        padding: '40px',
        width: '36%',
        border: '1px solid #4ec6fc91',
        borderRadius: '10px',
    };

    const handleSubmitEmail = async (e) => {
        e.preventDefault();
        try {
            const requestedData = {
              email: email,
            };
            const response = await axios.post(
              `${apiUrl}auth/forgetPasswordRequest`,
              requestedData
            );
            if (response.data.status === true) {
              toast.success("Mail sent successful", { autoClose: 1000 });
              setShowOtpPasswordForm(true);
            } else {
              toast.error(response.data.message || "An error occurred");
            }
          } catch (error) {
            toast.error("An error occurred");
          }
    };

    const handleSubmitOtpPassword = async (e) => {
        e.preventDefault();
        try {
            const requestedData = {
                otp: otp,
                email: email,
                password: password,
              };
              const response = await axios.post(
                `${apiUrl}auth/verifyPassword`,
                requestedData
              );
              if (response.data.status === true) {
                toast.success("Password changed successful", { autoClose: 1000 });
                setTimeout(() => {
                    navigate("/login");
                }, 1000);
              } else {
                toast.error(response.data.message || "An error occurred");
              }
        } catch (error) {
            toast.error("An error occurred");
        }
    };

    const checkAuthAndPaidStatus = () => {
        const encryptedUserId = localStorage.getItem("userId");
        if (encryptedUserId != null && encryptedUserId != undefined && encryptedUserId != "") {
            navigate("/error401");
        } 
      };
    
      useEffect(() => {
        checkAuthAndPaidStatus();
      }, []);
    

    return (
        
        <div className="login_bg d-flex justify-content-center align-items-center" style={{ height: '100vh', background: '#e5f7ff' }}>
            <ToastContainer /> {/* Render ToastContainer */}
            <div className="container">
                <div className="row">
                    <div className="login_form d-flex justify-content-center align-items-center flex-column ">
                        <div className="mb-5">
                            <a href="/" className="btn btn-web display-flex align-items-center" style={{ fontSize: '14px' }}>
                                <FaHome /> &nbsp; Back To Home Page
                            </a>
                        </div>

                        <div className="frmLogin" style={frmLogin}>
                            <h4 className="pb-3">Forget Password Section</h4>

                            {!showOtpPasswordForm && (
                                <form onSubmit={handleSubmitEmail}>
                                <div className="form-floating mb-4">
                                    <input
                                        id="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="mt-2">
                                    <button type="submit" className="btn btn-web display-flex align-items-center" style={{ fontSize: '14px' }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                            )}

                            {showOtpPasswordForm && (
                                <form onSubmit={handleSubmitOtpPassword}>
                                    <div className="form-floating mb-4">
                                        <input
                                            id="otp"
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter your OTP"
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            required
                                        />
                                        <label htmlFor="otp">OTP</label>
                                    </div>

                                    <div className="form-floating mb-4">
                                        <input
                                            id="password"
                                            type="password"
                                            className="form-control"
                                            placeholder="Enter your new password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <label htmlFor="password">New Password</label>
                                    </div>

                                    <div className="mt-2">
                                        <button type="submit" className="btn btn-web display-flex align-items-center" style={{ fontSize: '14px' }}>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
